<form #signupForm="ngForm" (ngSubmit)="signup(signupForm)">
    
    <div class="content">

        <div class="left">
            
            @if (signUpMode == SignUpMode.Trial) {
                <h1>Try SalesPrep<sup>&reg;</sup> software — FREE!</h1>
                <div>
                    No credit card needed.
                    <br><br><br>
                    Near the end of your trial, look for an email from 
                    <a href="https://theaiminstitute.com">The AIM Institute</a> to continue your subscription. 
                </div>
            } @else {
                <h1>
                    Subscribe to SalesPrep<sup>&reg;</sup> software
                    @if (signUpMode == SignUpMode.EvocWorkshop) {
                        + Public Workshop
                    }
                </h1>
    
                <div class="subtitle">
                    @if (signUpMode == SignUpMode.Monthly) {
                        Only $24.95 per month, billed monthly
                    } @else if (signUpMode == SignUpMode.Yearly) {
                        Only $19.95 per month, billed yearly
                    } @else {
                        $495 is due the first year
                    }
                </div>
                <div>
                    <br>
                    After verifying your email, you’ll be asked to confirm subscription and payment options.                    
                </div>
                
                @if (signUpMode == SignUpMode.EvocWorkshop) {
                    <div>
                        <br>
                        Once your subscription is confirmed, look for an email from 
                        The AIM Institute to select the 
                        <a target="_blank" href="https://theaiminstitute.com/aim-product-development-training/everyday-voc-workshops/" style="font-weight: 500">
                        upcoming workshop date</a> that works best for you. 
                    </div>
                }
            } 
            
            <div class="link desktop-layout">Already have an account? <a class="sp-link" [routerLink]="['/login']">Log in</a></div>
        </div>
        
        <div class="sp-form right">
    
            <label for="firstName">First Name</label>
            <input appAutofocus type="text" id="firstName" [(ngModel)]="user.firstName" required name="firstName" placeholder=" ">
    
            <label for="lastName">Last Name</label>
            <input type="text" id="lastName" [(ngModel)]="user.lastName" required name="lastName" placeholder=" ">
            
            <label for="email">Email</label>
            <input autocomplete="login" appAutofocus [autofocus]="!!user.firstName" type="email" id="email" [(ngModel)]="user.email" required name="email" placeholder=" ">
    
            <label for="password">Password</label>
    
            <div class="flex">
                <div class="password-input">
                    <input autocomplete="current-password" [type]="showPassword ? 'text' : 'password'" id="password" [(ngModel)]="user.password" required name="password" placeholder=" ">
        
                    <a (click)="togglePasswordVisibility()">
                        <i class="material-icons">@if(showPassword) { visibility_off } @else { visibility }</i>
                    </a>
                </div>
                <div class="password-hints">
                    <ul>
                        <li style="white-space: nowrap">8 characters minimum</li>
                        <li>a number</li>
                        <li>a special character<br>!{{'@'}}#$%^&*()_+<></li>                
                    </ul>
                </div>
            </div>
    
            @if (signUpMode == SignUpMode.Trial) {
                <label for="promoCode">Promo Code</label>
                <input type="text" id="promoCode" (blur)="onValidatePromoCode.emit(user.code)" [(ngModel)]="user.code" name="promoCode" class="promoCode" placeholder=" ">
            }
            
            @if (trialPeriod) {
                <div class="promo-hint">Promo code applied: {{ trialPeriod }}-day free trial</div>
            }
    
            <div class="sp-checkbox">
                <input id="agreement" type="checkbox" [checked]="agreed" (change)="agreed = !agreed" value="1">
                <label for="agreement" class="terms">
                    I agree to the SalePrep <a [routerLink]="['/terms']">terms</a>.
                    Learn about how we use and protect your data in our <a [routerLink]="['/privacy']">Privacy Policy</a>.
                </label>
            </div>
            
            @for (error of errorMessages; track error) {
                <app-error-message [text]="error"></app-error-message>
            }
            
            <button class="primary" type="submit" [disabled]="!agreed || signupForm.invalid">

                @if (signUpMode == SignUpMode.Trial) {
                    Start Trial
                } @else {
                    Subscribe to SalesPrep
                }
            </button>
    
            <!--<button class="google" type="button" (click)="onSignInWithGoogle()">
                <div class="icon" [class.sp-spin]="signInWithGoogleActive"></div>
                <div class="text">Sign up with Google</div>
            </button>-->
    
        </div>

    </div>
    
</form>