import {
    Component,
    EventEmitter,
    Host,
    HostBinding,
    Input,
    OnChanges,
    Output,
    signal,
    SimpleChanges
} from '@angular/core';
import {DiscussionTopicsListComponent} from "../discussion-topics-list/discussion-topics-list.component";
import {MainPanelComponent} from "../../../../../../components/main-panel/main-panel.component";
import {
    ReportBuilderHeaderComponent
} from "../../../../components/report-builder-header/report-builder-header.component";
import {Offering} from "../../../../../../model/offering";
import {
    Company,
    DiscussionTopic,
    DiscussionTopics, ExploratoryQuestion,
    NewsArticle,
    ReportSectionRating,
    Stakeholder
} from "../../../../../../model";
import {InfoPanelComponent} from "../../../../../../components/info-panel/info-panel.component";
import {SpinnerComponent} from "../../../../../../components/spinner/spinner.component";
import {DatePipe} from "@angular/common";
import {TopProgressBarComponent} from "../../../../components/top-progress-bar/top-progress-bar.component";
import {FormsModule} from "@angular/forms";
import {AutofocusDirective} from "../../../../../../directives/autofocus.directive";
import {SideProgressBarComponent} from "../../../../components/side-progress-bar/side-progress-bar.component";
import {RouterLink} from "@angular/router";
import {RetryComponent} from "../retry/retry.component";
import {distinctUntilChanged} from "rxjs";
import {IEditableDiscussionTopics} from "../../../../../../services/report-builder.service";


export class NewsDiscussionTopic implements DiscussionTopic {
    // id: x.url,
    // selected: x.selected,
    // title: ,
    // description: x.summary

    get id() {
        return this.x.url;
    }
    
    get title() {
        return this.x.title;
    }
    get description() {
        return this.x.summary;
    }
    
    get selected() {
        return this.x.selected;
    }
    set selected(selected: boolean) {
        this.x.selected = selected;
    }
    
    get url() {
        return this.x.url;
    }

    urlText = '';
    
    constructor(private x: NewsArticle, datePipe: DatePipe) {
        this.urlText = x.source + ': ' + datePipe.transform(x.date);
    }
}
@Component({
    selector: 'app-pure-select-discussion-topics',
    standalone: true,
    providers: [
        DatePipe,
    ],
    imports: [
        DiscussionTopicsListComponent,
        MainPanelComponent,
        ReportBuilderHeaderComponent,
        InfoPanelComponent,
        TopProgressBarComponent,
        FormsModule,
        AutofocusDirective,
        SideProgressBarComponent,
        RouterLink,
    ],
    templateUrl: './pure-select-discussion-topics.component.html',
    styleUrl: './pure-select-discussion-topics.component.scss',
})
export class PureSelectDiscussionTopicsComponent implements OnChanges {
    
    @Input() loading = false;
    @HostBinding('class.inline')
    @Input() inlineMode = false;
    // @Input() loading = false;
    
    @Input() companyNewsLoading = false;
    @Input() companyNewsLoadingFailed = false;

    @Input() exploratoryQuestionsLoading = false;
    @Input() exploratoryQuestionsLoadingFailed = false;
    @Input() questions: ExploratoryQuestion[] = [];
    
    @Input() stakeholdersLoading = false;
    @Input() stakeholdersLoadingFailed = false;
    
    @Input() company: Company | undefined;
    @Input() product!: Offering;
    @Input() job = '';
    
    @Input() discussionTopics: IEditableDiscussionTopics[] = [];
    @Input() stakeholders: Stakeholder[] = [];
    @Input() otherTopics: DiscussionTopic[] = [];
    @Input() newsArticles: NewsArticle[] = [];
    
    @Input() companyNewsRating!: ReportSectionRating;
    @Input() stakeholdersRating!: ReportSectionRating;
    @Input() marketTrendsRating!: ReportSectionRating;
    @Input() commonProblemsRating!: ReportSectionRating;
    @Input() processStepsRating!: ReportSectionRating;
    @Input() otherTopicsRating!: ReportSectionRating;
    @Output() onRatingChange = new EventEmitter<ReportSectionRating>();    

    @Input() selectedCustomDiscussionTopic: DiscussionTopic | undefined;
    @Input() customTopic = '';

    @Output() onCancelEditMode = new EventEmitter();
    @Output() onNext = new EventEmitter();
    @Output() onAddDiscussionTopic = new EventEmitter();
    @Output() onSaveCustomTopic = new EventEmitter<DiscussionTopic>();

    @Output() onCompanyDataRetry = new EventEmitter();
    @Output() onStakeholdersRetry = new EventEmitter();
    @Output() onMarketTrendsRetry = new EventEmitter();
    @Output() onCommonProblemsRetry = new EventEmitter();
    @Output() onProcessStepsRetry = new EventEmitter();

    @HostBinding('class.sp-screen') s = true;
    
    title = signal('');

    selectedTopic = signal<DiscussionTopic | undefined>(undefined);
    newsTopics: DiscussionTopic[] = [];
    addOtherTopic = false;
    
    constructor(private datePipe: DatePipe) {
    }
    
    ngOnChanges(changes: SimpleChanges) {
        
        if (changes['selectedCustomDiscussionTopic']) {
            this.addOtherTopic = !this.selectedCustomDiscussionTopic?.title;
        }
        if (changes['newsArticles']) {
            if (this.newsArticles) {
                this.newsTopics = this.newsArticles.map(x => {
                    return new NewsDiscussionTopic(x, this.datePipe);
                });
            } else {
                this.newsTopics = [];
            }
        }
    }

    onSelectNewsArticle(topic: DiscussionTopic) {
        this.title.set('Company News');
        
        const a = this.newsArticles.find(x => x.url == (<any>topic).id);
        if (a) {
            this.selectedTopic.set({
                selected: true,
                title: topic.title,
                description: a.summary
            })
        }
    }

    cancelAddTopic() {
        this.selectedCustomDiscussionTopic = undefined;
        this.customTopic = '';
    }

    saveCustomTopic() {
        this.onSaveCustomTopic.emit(this.selectedCustomDiscussionTopic);
        this.cancelAddTopic();        
    }

    save(q: ExploratoryQuestion) {
        console.log('[PureExploratoryQuestionsComponent] save', q);
        q.edit = false;
    }

    edit(e: MouseEvent, q: ExploratoryQuestion) {
        e.preventDefault();

        console.log('[PureExploratoryQuestionsComponent] edit', q);
        q.edit = true;
    }

    add() {
        this.questions.push({
            edit: true,
            selected: true,
            text: ''
        })
    }

    toggle(q: ExploratoryQuestion) {
        q.selected = !q.selected;
    }
}
