@if (!inlineMode) {
    <app-top-progress-bar [step]="4" />
    <app-side-progress-bar [step]="4" [company]="company" [offering]="product" [job]="job" />
}

<app-main-panel [class.inline]="inlineMode" class="left-column">
    
    <form>
        
    @if (!inlineMode) {
        <div class="flex mobile-layout">
            <app-report-builder-header [company]="company" [offering]="product" [job]="job"/>
        </div>
    }
    
    <div class="sp-form">
        
        <div class="inputs">

            @if (!inlineMode) {
                <div class="flex">
                    <div class="title main">
                        <strong>4.&nbsp;</strong>
                    </div>
                    <div class="title main">
                        <span><strong>Meeting Details</strong> below are all <em>optional</em>:</span>
                        <br><span>&nbsp;&bullet; Answer 4a-4c to <em>optimize</em> your <em>Meeting Openers</em></span>
                        <br><span>&nbsp;&bullet; Answer 4d-4f to <em>create</em> a faster <em>Call Report</em> later</span>
                    </div>
                </div>
            }    
            
            <div class="title" qa="type">
                <strong>@if (!inlineMode) { 4a. }Who will you meet with?</strong>
            </div>            

            @for (role of customers; track role; let index = $index) {
                <div class="two-columns role" [attr.qa]="'role-' + (index + 1)">

                    <div class="flex-column">
                        <label [for]="'name' + index">First & last name</label>
                        <input appAutofocus [autofocus]="!role.name" [id]="'name-' + index" [name]="'name-' + index" [autofocus]="!role.name" type="text"
                               [(ngModel)]="role.name" required
                               qa="name">
                    </div>

                    <div class="flex-column">
                        <label [for]="'role' + index">Role (e.g. design engineer)</label>
                        <input [id]="'role-' + index" [name]="'role-' + index" [autofocus]="!role.title" type="text" [(ngModel)]="role.title" required
                               qa="role">
                    </div>

                    <a (click)="removeRole(index)" class="remove" title="remove role">
                        <span class="material-symbols-outlined" qa="role-x">close</span>
                    </a>
                </div>
            }

            <button (click)="addRole()" type="button" class="add-role alternative">
                <i class="material-icons">add</i>
                <span class="text" qa="add-role">Add customer</span>
            </button>
        
            <div class="sp-selectable" qa="first-meeting">
                <input type="radio" [value]="MeetingType.InPerson" id="first-meeting" name="relationship" [(ngModel)]="meetingDetails.relationshipType" qa="input">
                <label for="first-meeting" qa="label">{{ RelationshipType.FirstMeeting | relationshipType }}</label>
            </div>
            <div class="sp-selectable" qa="existing-relationship">
                <input type="radio" [value]="MeetingType.Virtual" id="existing-relationship" name="relationship" [(ngModel)]="meetingDetails.relationshipType" qa="input">
                <label for="existing-relationship" qa="label">{{ RelationshipType.ExistingRelationship | relationshipType }}</label>
            </div>

            <div class="title" qa="type">
                <strong>@if (inlineMode) {4b. }What is the purpose of your meeting? @if (!inlineMode) {<em>(check all that apply)</em>}</strong>
            </div>
        
            @for (purpose of purposes; track purpose.text; let i = $index) {
                <div class="sp-selectable" [attr.qa]="'purpose-' + i">
                    <input type="checkbox" [checked]="purpose.selected" (change)="toggle(purpose)" [id]="'purpose-' + i" [name]="'purpose-' + i"  qa="input">
                    <label [for]="'purpose-' + i" qa="label">{{ purpose.text }}</label>
                </div>
            }

            <div class="sp-selectable" [attr.qa]="'purpose-custom'" (click)="customerPurposeText.focus()">
                <input type="checkbox" [checked]="!!customPurpose" (click)="customerPurposeText.focus()" [id]="'purpose-custom'" [name]="'purpose-custom'" qa="input">
                <label [for]="'purpose-custom'" qa="label">
                    <input #customerPurposeText name="custom-purpose-text" type="text" [(ngModel)]="customPurpose" placeholder="Add another purpose">
                </label>
            </div>
            
        
            <div class="benefits">
                <h1 class="title"><strong>@if (inlineMode) {4c. }My offering can help the customer...&nbsp;</strong> <em>(begin with a verb)</em></h1>

                @for (tip of potentialBenefitsTips; track tip; let i = $index) {
                    <div class="benefit" [attr.qa]="'tip-' + (i + 1)">
                        <h1 class="title" qa="tit">{{ i + 1 }}.</h1>
                        <input type="text" [(ngModel)]="meetingDetails.potentialBenefits[i]" placeholder="{{ tip }}" [name]="'tip-' + i" qa="ben">
                    </div>
                }
            </div>

            <div class="title time" qa="date">
                <strong>@if (!inlineMode){ 4d. }Select your meeting date</strong>
            </div>

            <div class="sp-selectable" qa="today">
                <input type="radio" value="{{today | date:'yyyy-MM-dd'}}" id="date-today" checked="checked" name="date" [(ngModel)]="selectedDate" qa="input">
                <label for="date-today" qa="label">Today, {{today | date:"MMM d"}}</label>
            </div>

            <div class="sp-selectable" qa="tom">
                <input type="radio" value="{{tomorrow | date:'yyyy-MM-dd'}}" id="date-tomorrow" name="date" [(ngModel)]="selectedDate" qa="input">
                <label for="date-tomorrow" qa="label">Tomorrow, {{tomorrow | date:"MMM d"}}</label>
            </div>

            <div class="sp-selectable" qa="select" (click)="showDatePicker = true">
                <input type="radio" [checked]="!!selectedDate" value="custom" id="date-custom" name="date" #customDate [(ngModel)]="selectedDate" qa="input1">

                <label for="date-custom" qa="label">
                    @if (datePickerValue) {
                        {{datePickerValue | date: "MMM dd, yyyy"}}
                    } @else {
                        Pick date
                    }
                </label>
                <span (click)="showDatePicker = true;showTimePicker = false;" class="material-icons">calendar_today</span>
            </div>

            <!--            <div class="sp-selectable" qa="t-unsure">-->
            <!--                <input type="radio" value="" id="date-unsure" name="date" [(ngModel)]="selectedDate" qa="input">-->
            <!--                <label for="date-unsure" qa="label">Unsure</label>-->
            <!--            </div>-->
            <!--            -->

            <div class="title" qa="type">
                <strong>@if (!inlineMode){ 4e. }Will this meeting be in-person or remote?&nbsp;</strong> <em>(phone or video)</em>
            </div>
            <div class="sp-selectable" qa="in-person">
                <input type="radio" [value]="MeetingType.InPerson" id="in-person" name="meeting-type" [(ngModel)]="meetingDetails.meetingType" qa="input">
                <label for="in-person" qa="label">{{ MeetingType.InPerson | meetingType }}</label>
            </div>
            <div class="sp-selectable" qa="remote">
                <input type="radio" [value]="MeetingType.Virtual" id="remote" name="meeting-type" [(ngModel)]="meetingDetails.meetingType" qa="input">
                <label for="remote" qa="label">{{ MeetingType.Virtual | meetingType }}</label>
            </div>
<!--            <div class="sp-selectable" qa="unsure">-->
<!--                <input type="radio" [value]="MeetingType.Unknown" id="unsure" name="meeting-type" [(ngModel)]="meetingDetails.meetingType" qa="input">-->
<!--                <label for="unsure" qa="label">Unsure</label>-->
<!--            </div>            -->

            <div class="title time" qa="location">
                <strong>@if (!inlineMode){ 4f. }Location of the customer with whom you are meeting</strong>
            </div>
            
            <div class="two-columns">
                <div class="flex-column">
<!--                    <label for="city">City</label>-->
                    <input id="city" type="text" name="city" [(ngModel)]="meetingDetails.customerCity" qa="city" placeholder="City">
                </div>
    
                <div class="flex-column">
<!--                    <label for="state">State or Country</label>-->
                    <input id="state" type="text" name="state" [(ngModel)]="meetingDetails.customerState" qa="state" placeholder="State or Country">
                </div>
            </div>

            @if (!inlineMode) {
                <div class="title" qa="options">
                    <strong>4g. Report generation options</strong>
                </div>


                <!--<button class="primary generate" (click)="save(false)" [disabled]="saving" qa="save">
                    Generate Report Now
                    <div>Best when you're in a hurry or don't know the customer well</div>
                </button>


                <button class="primary generate topics" (click)="save(false)" [disabled]="saving" qa="save">
                    Select Report Topics
                    <div>Use your industry & customer expertise to craft a more tailored report</div>
                </button>

                <button [routerLink]="['/report-builder', 'job']" class="generate back desktop-layout" qa="back">
                    Back
                </button>-->
                
                <div class="sp-selectable" qa="quick">
                    <input type="radio" [value]="ReportGenerationTypes.Quick" id="quick" name="quick" [(ngModel)]="reportGenerationType" qa="input">
                    <label class="richtext" for="quick" qa="label">
                        <span>Generate report now</span>
                        <em>Best when you're in a hurry or don't know the customer well</em>                        
                    </label>
                </div>
                <div class="sp-selectable" qa="detailed">
                    <input type="radio" [value]="ReportGenerationTypes.Detailed" id="detailed" name="detailed" [(ngModel)]="reportGenerationType" qa="input">
                    <label class="richtext" for="detailed" qa="label">
                        <span>Select report topics first</span>
                        <em>Use your industry &amp; customer expertise to craft a more tailored report.</em>
                    </label>
                </div>
            }
        </div>
        
    
        <div class="actions" qa="actions">
            @if (inlineMode) {
                <button type="button" (click)="onCancelEditMode.emit()" class="back desktop-layout" qa="back">
                    Back
                </button>
                <button class="primary" (click)="save(false)" [disabled]="saving" qa="save">
                    Save
                </button>
            } @else {
                <button [routerLink]="['/report-builder', 'job']" class="back desktop-layout" qa="back">
                    Back
                </button>

                <button class="primary" (click)="save(reportGenerationType == ReportGenerationTypes.Quick)" qa="save">
                    {{ reportGenerationType == ReportGenerationTypes.Quick ? 'Generate Report' : 'Select Report Topics' }}
                </button>
            }
            
        </div>
    </div>

    </form>
</app-main-panel>

@if (showDatePicker) {
    <app-info-panel [title]="'Select Date'" (onClose)="showDatePicker = false">
        <app-date-picker [(date)]="datePickerValue" (dateChange)="showDatePicker = false"/>        
    </app-info-panel>
}
@if (showTimePicker) {
    <app-info-panel [title]="'Select Time'" (onClose)="showTimePicker = false">
        <app-time-picker [(time)]="meetingDetails.time" (timeChange)="selectedTime = 'custom'" (onTimSelected)="showTimePicker = false" />
    </app-info-panel>
}
